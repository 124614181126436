

@import "~bootstrap/dist/css/bootstrap.min.css";
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');


body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','sans-serif';
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}


@media only screen and (max-width: 600px) {
  body {
    font-size: 14px;
  }
}

@media only screen and (max-width: 900px) {
  body {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1200px) {
  body {
    font-size: 15px;
  }
}

nav.navbar{
 background-color: #f0f0f0;
 width: 100%;
}

div.navbar{
  background-color: #f0f0f0;
}

ul.dropdown-menu.show{
  background-color: #f0f0f0;
}

a.dropdown-item{
  color: #695a7b;
}
a.nav-link{
  color: #695a7b;
  
}


.navbar-brand{
 font-size: 28px;
  position: relative;
  left:25px;
  color: #695a7b;

}

.navbar-brand:hover{
  color: #b1a7ab; 
}

.navbar:hover{
  color:#b1a7ab;
}

.home-container{
  
  width: 100vw;
  height: 100%; 
  overflow-y: auto; /* Add vertical scrollbar  */
  background: linear-gradient(to right,
 
  #ffe4e1 1%,
  
  #b1a7ab 25%,
 
  #8c8188 50%,
 
  #695a7b 75% 

); 
}


.page-content {
  padding: 20px; 
}

.name,
.slogan {
  text-align: center;
  position: relative;
  top:8rem;

 
}

.name {
  font-size: 5rem;
  color: #f0f0f0;
  font-family:'Playfair Display', serif;
}

a {
  text-decoration: none;
}



@media only screen and (max-width: 600px) {
  .name {
    position: relative;
    display: flex;
    justify-content: center;
    right: 5px;
  }
}

.slogan {
  font-size: 27px;
  color: #f0f0f0;
  white-space: pre-line;
  font-family: 'Playfair Display', serif;
  position: relative;
  top:11rem;
}

@media only screen and (max-width: 600px) {
  .slogan {
    position: relative;
    display: flex;
    justify-content: center;
  }
}
 



/* contact page css */

form.contact-form{
  background-color: #f0f0f0;
  width:70%;
  margin: auto;
  margin-top: 10rem;
  margin-bottom: 3rem;
  border-radius: 12px;


label{
  color:#695a7b;
}
  
}
.contact-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
 
}

.form-container {
  max-width: 500px;
  width: 100%;
  
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

textarea {
  height: 150px;
}

button {
  background-color: #695a7b;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

button:hover {
  background-color: #8c8188;
}


h1.form{
  color: #695a7b;
}

/* about page */

.skills-container {
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  border-radius: 6px;
  color: #695a7b;
  font-size: 18px;
  position: relative;
  bottom:3rem;
}

@media only screen and (min-width: 600px) {
  .skills-container {
    width: 60%;
  }
}

@media only screen and (min-width: 900px) {
  .skills-container {
    width: 80%;
    margin: 20px 0;
  }
}


.about-me-container,
.education-container
 {
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  border-radius: 6px;
  color: #695a7b;
  font-size: 18px;
  justify-content: space-evenly;
}

@media only screen and (min-width: 600px) {
  .about-me-container,

  .education-container {
    width: 60%;
  }
}

@media only screen and (min-width: 900px) {
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about-me-container,
  .education-container {
    width: 80%;
    margin: 20px 0;
  }
}


.education {
  text-align: center; 
  margin: 20px auto; 
  

}
div.about-me-container{
  margin-bottom: 7rem;
  margin-top: 7rem;
}


div.content-container{
  margin-bottom: 10rem;
  margin-top: 15rem;
  justify-content: space-between;
}

.education-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.education-row > div {
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
  margin: 10px; 
}

/* slower bouncing animation */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}


.icons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  animation: bounce 2s infinite; 
  font-size: 5rem;
  flex-wrap: wrap;
}


/* projects page */
.card {
  margin: 10px;
  transition: transform 0.3s ease-in-out;
}

.card:hover{
  transform: scale(1.05); 
}

/* Responsive design */
@media only screen and (max-width: 767px) {
  .col {
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col {
    flex: 0 0 50%;
  }
}

@media only screen and (min-width: 992px) {
  .col {
    flex: 0 0 33.3333333%;
  }
}



div.application{
  font-size: 18px;
  color: #695a7b;
}

.card-body{
  color: #695a7b;
}

.project-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
.demo-code-button{
  position: relative;
 margin: auto;
 top:15px;

}

.demo-button,.code-button{

  justify-content: space-evenly;
  position: relative;
  bottom:10px;
 margin:1rem;
align-items: center;
}

.applications-project1,
.applications-project2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; 
  width: 100%;
  gap:2px;
}

.application {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  flex: 1;
}

.application:hover {
  background-color: #e0e0e0;
}

.application svg {
  margin-bottom: 5px;
}


/* stroll to top */


.scrollToTopButton {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  color: #f0f0f0; 
  cursor: pointer;
  transition: opacity 0.3s ease;
  font-size: 35px;
}

.scrollToTopButton.show {
  display: block;
}

.scrollToTopButton:hover {
  color: #b1a7ab; 
}

img{
  height: 300px;
}

/* FOOTER  */

.footer-content{
  background-color: #f0f0f0;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #695a7b;

}

.icon-container{
  color: #695a7b;
  font-size: 25px;
 
  
}

